import Vue from 'vue'
import App from './App.vue'
import router from './router'
import $ from 'jquery'
import 'dtoaster/dist/dtoaster.css'
import DToaster from 'dtoaster'
import ToasterPresets from "../public/assets/toaster/toast_presets.json"
import VueCompositionAPI from '@vue/composition-api'


Vue.config.productionTip = false;

Vue.use(DToaster, {
  presets: ToasterPresets,
  position: 'top-right',
  containerOffset: '45px',
});
Vue.use(VueCompositionAPI);

new Vue({
  data: {

    wallet_address: null,
  },
  created: function(){
    
  },
  methods: {

    
    
  },
  watch:{
    
  },
  router,
  render: h => h(App)
}).$mount('#app')
