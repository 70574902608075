<template>
	<div id="app">
		<div id="wrapper">
			<div id="page" class="clearfix">


				<Alert></Alert>
				<router-view/>
				
			</div>
			<!-- /#page -->

			
		</div>
    
	</div>
</template>

<script>
import Alert from '@/components/Alert.vue';

export default {
	name: 'App',
	components: {
    Alert
	},
	data: function() {
		return {
		
		}

	},
	computed: {

	},
	mounted: function(){


	},
}

</script>

<style>

</style>
